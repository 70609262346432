@use "styles" as *;

div.overlay {
  z-index: 99;
}

.button {
  padding: 10px;
  display: flex;

  @include sm-desktop {
    padding: 0;
  }
}

.menuMobileButton {
  padding: 10px;
  display: flex;

  @include sm-desktop {
    display: none;
  }
}

.searchButton {
  @include text-xs-regular;

  align-items: center;
  gap: 8px;
}

div.dialogHeader {
  padding: 8px 10px;
  height: 40px;
  @include sm-desktop {
    padding: 8px 16px;
  }
}
div.dialogTitle {
  flex-grow: 1;
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    flex-grow: 1;
  }
}
div.dialogWrapper {
  align-items: flex-start;
  flex-direction: column;
}
div.dialogPanel {
  flex-grow: 1;
}
.searchInput {
  @include text-s-regular;
  border: initial;
  appearance: initial;
  flex-grow: 1;
  outline: none;
  background: initial;
  &:focus-visible {
    outline: 1px $dark;
    outline-offset: 2px;
  }
  &::placeholder {
    color: $dark;
  }
}

// reset dialog styles
div.dialogContent {
  box-shadow: initial;
  border-radius: initial;
  background-color: initial;
  padding: initial;
  flex-grow: 1;
  position: relative;
  // min-height: 100%;
}

.nav {
  position: absolute;
  min-height: 100%;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-content: flex-start;
  gap: 8px;
  // safe bottom area for scrollable div
  padding-bottom: 12px;
}

.box {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 8px 12px 8px 14px;
  width: 100%;

  &.isHighlighted {
    @include color-gradient-light;
    height: 52px;
  }
}

.boxAccount {
  @extend .box;
  width: calc(50% - 4px);
  a {
    height: 44px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.countryLangButton {
  @include button-shadow;
  @include rounded-8;
  @include text-xs-regular-uppercase;

  background-color: $white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  // position: absolute;
  // bottom: 12px;
}

.heading {
  flex-direction: row;
  align-items: center;
  height: 60px;
  gap: 12px;
  padding: 0 16px 0 12px;
  align-items: stretch;
  button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    gap: 12px;
  }
  span {
    @include text-s-bold-uppercase;
    flex-grow: 1;
  }
  a {
    @include text-s-regular;
    @include text-underline;
    display: flex;
    align-items: center;
  }
}

.secondLevel {
  padding: 0;
}

.mainMenu,
.highlightMenu {
  display: flex;
  flex-direction: column;
  padding: 8px 12px 8px 14px;
}

.highlightMenu {
  background-color: $light-pink;
}

.collectionsBox {
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  span {
    @include text-s-regular-uppercase;
  }
}

.collectionsWrapper {
  padding: 12px;
  gap: 8px;
}

.servicesBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.servicesWrapper {
  gap: 8px;
}
