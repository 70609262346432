@use "styles" as *;

.center {
  white-space: pre;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: auto;
  @include hide-scrollbar;

  @include sm-desktop {
    width: 40%;
    margin: auto;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInReadMore {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

div.swiper {
  width: 100%;
}

div.swiperSlide {
  @include text-xxs-regular-uppercase;
  line-height: 140%;
  margin: auto;
  text-align: center;
  height: $header-topbar-mobile;

  @include sm-desktop {
    @include text-xs-regular-uppercase;
    height: $header-topbar-desktop;
  }

  &.initialAnimation {
    animation: fadeIn 1.5s ease-in-out;
  }

  &.swiperSlideReadMore {
    height: auto;
    animation: fadeInReadMore 1.5s ease-in-out;
  }
}

.containerSlide {
  width: 100%;
  height: 100%;
  align-content: center;
}

.buttonReadMore {
  margin-inline-start: 8px;
  flex-shrink: 0;
  @include text-xxs-regular;
  text-decoration: underline;
}

.link,
.text {
  white-space: normal;
}

.link {
  text-decoration: underline;
}
