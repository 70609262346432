@use "styles" as *;

.loginContainer {
  padding: 20px 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  &.modalLoginContainer {
    padding: 0 4px;

    .wrapperSocialLogin {
      gap: 12px;
      flex-direction: row;
    }
  }

  .loginHeadline {
    @include text-s-bold-uppercase;
    text-align: center;
  }

  .modalLoginHeadline {
    @include text-xs-bold-uppercase;
  }

  .modalLoginParagraph {
    @include text-xs-regular;
    line-height: 16px;
  }

  .inputFieldContainer {
    margin: 10px 0 14px;
  }

  .privacyDisclaimer {
    @include text-xxs-regular;
    margin-block-end: 20px;
  }

  .privacyPolicyLink {
    @include text-underline;
  }

  .loginButton {
    width: 100%;
    @include text-s-bold-uppercase-cta;
  }

  .paragraph {
    @include text-s-bold-uppercase;
    text-align: center;
  }

  .wrapperHandlePassword {
    display: flex;
    justify-content: space-between;
    margin-block-end: 20px;

    .checkboxRememberMe {
      @include text-xxs-regular;
      line-height: 14px;
      align-items: center;
    }

    .forgotPassword {
      @include text-xxs-regular;
      @include text-underline;
      text-underline-offset: 3px;
      margin-block-start: 4px;
    }
  }

  .socialLoginHeadline {
    text-align: center;
    @include text-xs-regular;
    line-height: 16px;
    margin: auto 0;

    @include mobile-only {
      display: none;
    }
  }

  .wrapperSocialLogin {
    display: flex;
    justify-content: space-evenly;
    gap: 12px;

    @include sm-desktop {
      flex-direction: column;
      gap: 20px;
    }

    .socialLogin {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 8px 4px;
      width: 100%;
      background-color: $white;
      @include rounded-8;
      @include text-xxs-semibold-uppercase-cta;
      @include button-shadow;

      &:disabled {
        cursor: initial;
        opacity: 0.5;
      }
    }
  }

  .mobileOnly {
    @include sm-desktop {
      display: none;
    }
  }

  .mediaDesktop {
    @include mobile-only {
      display: none;
    }
  }
}

.guestWrapper {
  p {
    @include text-s-bold-uppercase;
    text-align: center;
  }

  .proceedGuestLink {
    width: fit-content;
    margin: 0 auto;
    display: block;
  }
}