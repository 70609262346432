@use "styles" as *;

.searchWrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
  @include sm-desktop {
    gap: 16px;
  }
}

.searchHeading {
  @include text-s-bold-uppercase;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray;
  text-align: center;
  width: 100%;
}

.searchBox {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  width: 100%;
}

.searchAlert {
  @include color-gradient-light;
  padding: 8px;
  @include text-s-regular;
  text-align: center;
  margin-bottom: 16px;
}

.searchButton {
  margin-top: 16px;
}

.searchList {
  display: grid;
  width: 100%;
  column-gap: 12px;
  row-gap: 16px;
  grid-template-columns: 100%;
  padding-top: 16px;
  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }
  @include sm-desktop {
    grid-template-columns: repeat(3, 1fr);
  }
  @include md-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
}

.searchProduct {
  @include glass-shadow;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.searchProductCol {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.searchProductRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.searchShadesAvailables {
  @include text-s-regular;
  padding: 2px 4px 4px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid $light-gray;
  border-radius: 12px;
  margin-top: 4px;
}

.searchImg {
  display: block;
  flex-shrink: 0;
}

.searchSuggestions {
  align-items: flex-start;
  .searchHeading {
    text-align: left;
  }
}
.searchSuggestionList {
  padding-top: 16px;
  display: flex;
  gap: 16px;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  @include hide-scrollbar;
}

.searchSuggestionItem {
  @include text-s-regular;
}

.link {
  @include text-s-bold;
}

div.containerPrice {
  gap: 4px;
}

p.displayPrice {
  order: -1;
}

p.discount {
  @include text-xxs-bold;
  line-height: unset;
}

del.originalPrice {
  @include text-xxs-regular;
}

.ratings {
  margin-inline-start: auto;
}
