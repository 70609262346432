@use "styles" as *;

.loyaltyPushContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;
  padding-right: 4px;
  padding-left: 4px;
}

.loyaltyPushTitle {
  width: calc(100% - 20px);
  padding-block-end: 18px;
  @include text-xs-bold-uppercase;
}

.loyaltyPushDescription {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  @include text-xxs-regular;
  line-height: 14px;
  overflow: hidden;

  p {
    padding-block-end: 16px;
  }
}

.benefitList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 12px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding-block-end: 16px;
}

.benefitItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  width: calc(33% - 4px - 10px);
}

.benefitLabel {
  font-weight: $font-weight-bold;
  text-align: center;
}

.link {
  width: 100%;
  text-align: center;
}
