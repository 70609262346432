@use "styles" as *;

.collectionContainer {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  grid-column: 1 / span 4;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-evenly;
}

.showMedia {
  visibility: visible;
}
.hideMedia {
  visibility: hidden;
}

.mediaContainer {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  grid-column: 5 / span 8;
  position: relative;
  aspect-ratio: 16 / 9;

  img {
    object-fit: cover;
  }
}
