@use "styles" as *;

.navItem {
  @include text-s-regular-uppercase;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.collectionsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.imageCollectionsWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.imageBox {
  display: flex;
  width: 80px;
  img {
    border-radius: 50%;
    border: 2px solid white;
  }

  .wrapperMedia {
    height: 40px;

    &:nth-child(1) {
      z-index: 2;
    }
    &:nth-child(2) {
      z-index: 1;
      transform: translateX(-50%);
    }
    &:nth-child(3) {
      transform: translateX(-100%);
    }
  }
}

.serviceCategoryContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @include button-shadow;
  @include rounded-8;
  padding: 16px 17px;
  background-color: $white;
  overflow: hidden;
  display: flex;
}

.serviceCategoryTitle,
.serviceCategoryTitleHighlighted {
  @include text-xs-regular-uppercase-cta;
  @include color-gradient-light;
  padding: 4px 10px;
  width: fit-content;
  @include rounded-20;
  align-self: center;
  line-height: 140%;
}

.serviceCategoryTitleHighlighted {
  strong {
    @include text-xs-bold-uppercase-cta;
  }
}
