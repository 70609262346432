@use "styles" as *;

// start of the Algolia custom panel styles
.searchWrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
  top: 0 !important;
  margin: 0;
  background: initial;
  border: initial;
  box-shadow: initial;
  border-radius: initial;
  overflow: initial;
  @include sm-desktop {
    gap: 16px;
  }
}

.searchHeading {
  @include text-s-bold-uppercase;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray;
  width: 100%;
  display: flex;
  gap: 12px;
  justify-content: center;
}

.searchBox {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  width: 100%;
}

.emptyContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.searchAlert {
  @include color-gradient-light;
  padding: 8px;
  @include text-s-regular;
  text-align: center;
  margin-bottom: 16px;
}

.searchList {
  display: grid;
  width: 100%;
  column-gap: 12px;
  row-gap: 16px;
  grid-template-columns: 100%;
  padding-top: 16px;
  list-style: none;
  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }
  @include sm-desktop {
    grid-template-columns: repeat(3, 1fr);
  }
  @include md-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
}

.upperFirstLetter {
  &::first-letter {
    text-transform: uppercase;
  }
  mark {
    font-weight: bold;
    background-color: initial;
    color: inherit;
  }
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  mark {
    font-weight: bold;
    background-color: initial;
    color: inherit;
  }
}

// end of Algolia custom panel styles

.searchButton {
  margin-top: 16px;
}

.searchProduct {
  height: 100%;
  @include glass-shadow;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.searchProductCol {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  h3 {
    @include text-s-regular;
    font-weight: 400;
    display: flex;
    align-items: center;
    height: 34px;
  }
}

.searchProductRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.searchShadesAvailables {
  @include text-xs-regular;
  padding: 2px 4px 2px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid $light-gray;
  border-radius: 12px;
  margin-top: 2px;
}

.searchImg {
  display: block;
  flex-shrink: 0;
}

.searchSuggestions {
  align-items: flex-start;
}
.searchSuggestionList {
  padding-top: 16px;
  display: flex;
  gap: 16px;
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  @include hide-scrollbar;
}

.searchSuggestionItem {
  @include text-s-regular;
}

.link {
  @include text-s-bold;
}

.recentSuggestionWrapper {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
  align-items: start;
  @include sm-desktop {
    grid-template-columns: 1fr 1fr;
    column-gap: 72px;
  }
  .recentSuggestion:only-child {
    grid-column: span 2;
  }
  .searchHeading {
    justify-content: flex-start;
  }
  .searchList {
    display: flex;
    flex-wrap: wrap;
  }
}

.recentSuggestionLive {
  @include sm-desktop {
    margin-bottom: 1rem;
  }
  .searchHeading {
    justify-content: flex-start;
  }
  .searchList {
    display: flex;
    flex-wrap: wrap;
  }
}

.noResults {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 56px;
  h2 {
    @include text-mini-title;
  }
  p {
    @include text-s-regular;
    padding-top: 16px;
  }
}

.searchItem {
  @include text-xs-regular;
  display: flex;
  padding: 4px 10px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: $white;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.16);
  cursor: pointer;
  &.upp {
    text-transform: uppercase;
  }
}

.ratings {
  margin-inline-start: auto;
}
