@use "styles" as *;

.collectionsWrapper {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.collectionItem {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
}

.collectionTitle {
  @include text-s-bold-uppercase-cta;
  width: 17%;
  flex-shrink: 0;
}

.collectionItem {
  padding: 8px 0;
  gap: 8px;
  padding-inline-end: 1rem;
  @include text-s-regular;
  @include text-underline;
  &:hover,
  &:focus {
    .imgWrapper {
      @include peach-gradient;
    }
  }
}

.imgWrapper {
  padding: 2px;

  border-radius: 50%;
  display: flex;
}

.preview {
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid white;
  box-sizing: content-box;
}

.previewViewAll {
  @include color-gradient;
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
