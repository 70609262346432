@use "styles" as *;

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;
  padding-right: 4px;
  padding-left: 4px;
}

.title {
  @include text-xs-bold-uppercase;
}

.paragraph {
  @include text-xs-regular;
  line-height: 140%;
}

.benefitsButton {
  @include text-s-bold-uppercase-cta;
  text-align: center;
}

.pointsContainer {
  display: flex;
  gap: 8px;
}

.progressBarContainer {
  position: relative;
  min-width: 68px;
  width: 68px;
  min-height: 68px;
  height: 68px;
}

.progressBar {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.progressBarLabelsContainer {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.progressValue {
  display: inline-block;
}

.progressSeparator {
  display: inline-block;
}

.progressTotalNextLevel {
  display: inline-block;
}

.pointswrapper {
  display: flex;
  flex-direction: column;
  .levelName {
    @include text-xxs-highlighted;
  }
  .points {
    @include text-xs-regular-uppercase;
  }
  .nextLevel {
    @include text-xs-regular;
    line-height: 140%;
    display: inline-block;
    gap: 4px;
    margin-top: 8px;
    .bold {
      @include text-xs-bold;
    }
  }
}
