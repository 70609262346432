@use "styles" as *;

// add/remove panel
.notifyPanel {
  background: $white;
  @include sticky-shadow;
  @include sm-desktop {
    grid-column: 8 / span 5;
  }
  @include md-desktop {
    grid-column: 9 / span 4;
  }
}

// hover panel
.minicartPanel {
  @extend .notifyPanel;
  border-radius: 8px;
  overflow: hidden;
  padding: 0;
}

.topLine {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  gap: 8px 8px;
  grid-template-areas:
    "firstMessage close"
    "secondMessage secondMessage";
  @include text-xs-regular-uppercase;

  strong {
    @include text-xs-bold-uppercase;
  }
}

.close {
  grid-area: close;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.firstMessage {
  grid-area: firstMessage;
}

.warningMessage {
  grid-area: secondMessage;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  @include rounded-8();
  border: 1px solid $red-ko;
  padding: 8px;
  color: $red-ko;
}

.bottomLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
}

.minicartTop {
  @include text-xxs-bold-uppercase;
  @include color-gradient-light;
  text-align: center;
  padding: 8px 12px 16px;
}

.minicartContainer {
  position: relative;
  background: $white;
  z-index: 1;
  margin-top: -8px;
  border-radius: 8px;
}

.minicartList {
  // TODO understand if it fixes windows
  // @include hide-scrollbar;
  box-shadow: initial;
  padding: 16px 12px 8px;
  gap: 8px;
  max-height: max(470px, 50vh);
  overflow: auto;
}

.minicartButton {
  min-width: 148px;
  text-align: center;
}
