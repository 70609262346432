@use "styles" as *;

.nav {
  display: none;
  height: 32px;
  width: 100%;
  gap: 24px;
  align-items: stretch;
  justify-content: center;

  @include sm-desktop {
    display: flex;
  }
}

.navItem {
  position: relative;
  display: flex;
  align-items: center;
  @include text-xs-regular-uppercase;
}

.navItemActive {
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    height: 2px;
    width: 100%;
    bottom: 0;
    z-index: 1;
    background: $dark;
  }
}

.dialogOverlay {
  position: fixed;
  inset: 0;
  padding: 16px 12px;
  overflow-y: auto;
  @include white-gradient;
  z-index: 9;

  @include md-desktop {
    padding: 16px 32px;
  }
}

.dialogWrapper {
  position: relative;
  left: 0;
  max-width: $maxWidth;
  margin: 0 auto;
  top: var(--top-menu);
  padding-bottom: 16px;
}

div.navPanel {
  overflow: initial;
}

div.dialogTop {
  justify-content: space-between;
}
div.dialogHeader {
  flex-grow: 0;
  flex-shrink: 0;
  white-space: pre;
}
// reset dialog styles
div.dialogContent {
  box-shadow: initial;
  border-radius: initial;
  background-color: initial;
  padding: initial;
}

div.dialogTitle {
  font-weight: normal;
  text-transform: initial;
  @include ltr-only {
    letter-spacing: -0.02em;
  }
  @include text-underline;
}
