@use "styles" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.title {
  @include text-l-regular;

  font-weight: $font-weight-regular;
}

.description {
  @include text-xs-regular;
}

.links {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 36px;
}
