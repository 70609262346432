@use "styles" as *;

.menuContainer {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  display: flex;
  gap: 12px;
}

.highlightCol {
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: $light-pink;
  border: 1px solid inset rgba(219, 92, 0, 0.102);
  padding: 24px 12px;
  min-width: 16.7%;
  // flex-shrink: 0;
}

.mainCol {
  flex-grow: 1;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(5, 1fr);
}

.mainGroup {
  padding: 24px 12px;
}

.menuColumn {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
