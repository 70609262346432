@use "styles" as *;

.titleGroup {
  @include text-s-bold-uppercase-cta;
}

.itemsGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  a {
    @include text-s-regular;
    &:hover {
      @include text-underline;
    }
  }
}
