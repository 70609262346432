@use "styles" as *;

$topHeight: 44px;
$countryTabWrapperPaddingVerticalMobile: 10px;
$countryTabWrapperPaddingVerticalSmallDesktop: 16px;
$countryTabButtonHeight: 42px;

// Country Dialog styles
div.countryTabWrapper {
  --countryTabWrapper-gap: 4px;
  --countryTabWrapper-padding-vertical: #{$countryTabWrapperPaddingVerticalMobile};
  --countryTabWrapper-padding-horizontal: 0;
  --countryTabWrapper-height: 56px;

  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: var(--countryTabWrapper-gap);
  border-bottom: 1px solid transparent;
  @include border-color-gradient();
  border-image-slice: 1;
  padding: var(--countryTabWrapper-padding-vertical) var(--countryTabWrapper-padding-horizontal);
  overflow-y: hidden;
  overflow-x: auto;
  @include hide-scrollbar;

  @include sm-desktop {
    --countryTabWrapper-padding-vertical: #{$countryTabWrapperPaddingVerticalSmallDesktop};
  }
}

button.countryTabContainer {
  --countryTabContainer-marginInline-horizontal: 12px;
  position: relative;
  @include rounded-8;
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;

  &.clickedTab {
    outline: none;
  }

  &:first-of-type {
    margin-inline-start: var(--countryTabContainer-marginInline-horizontal);
    scroll-margin-inline-start: var(--countryTabContainer-marginInline-horizontal);
  }

  &:last-of-type {
    margin-inline-end: var(--countryTabContainer-marginInline-horizontal);
    scroll-margin-inline-end: var(--countryTabContainer-marginInline-horizontal);
  }

  @include sm-desktop {
    --countryTabContainer-marginInline-horizontal: 16px;
  }
}

div.countryTabButton {
  --countryTabButton-padding-vertical: 9px;
  --countryTabButton-padding-horizontal: 16px;
  --countryTabButton-height: #{$countryTabButtonHeight};
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  @include rounded-8;
  padding: var(--countryTabButton-padding-vertical) var(--countryTabButton-padding-horizontal);
  height: var(--countryTabButton-height);
  background-color: transparent;
  @include text-xs-regular-uppercase;
  transition-property: background, box-shadow;
  transition-timing-function: ease-out;
  transition-duration: 250ms;
}

div.countryTabButtonActive {
  @include button-shadow;
  @include color-gradient-light;
  @include rounded-8;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

div.countryListContainer {
  --countryListContainer-padding-top: 22px;
  --countryListContainer-padding-horizontal: 0;
  --countryListContainer-padding-bottom: 12px;
  --countryListContainer-gap-horizontal: 0;
  --countryListContainer-gap-vertical: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: var(--countryListContainer-padding-top) var(--countryListContainer-padding-horizontal)
    var(--countryListContainer-padding-bottom);
  max-height: calc(
    100svh - var(--wrapper-inset-vertical) - #{$topHeight} - var(--wrapper-inset-bottom) - var(--panel-gap) - 2 * #{$countryTabWrapperPaddingVerticalMobile} -
      #{$countryTabButtonHeight} - 1px
  );
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @include hide-scrollbar;

  @include sm-desktop {
    --countryListContainer-padding-top: 10px;
    --countryListContainer-padding-horizontal: 12px;
    --countryListContainer-padding-bottom: 20px;
    --countryListContainer-gap-horizontal: 32px;

    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--countryListContainer-gap-vertical) var(--countryListContainer-gap-horizontal);
    max-height: calc(
      100svh - var(--wrapper-inset-vertical) - #{$topHeight} - var(--wrapper-inset-bottom) - var(--panel-gap) - 2 * #{$countryTabWrapperPaddingVerticalSmallDesktop} -
        #{$countryTabButtonHeight} - 1px
    );
  }
}

div.countryListItem {
  --countryListItem-padding-vertical: 0;
  --countryListItem-padding-horizontal: 12px;
  --countryListItem-flex-basis: unset;
  --countryListItem-width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
  padding: var(--countryListItem-padding-vertical) var(--countryListItem-padding-horizontal);
  width: var(--countryListItem-width);
  height: 48px;

  @include sm-desktop {
    --countryListItem-padding-vertical: 12px;
    --countryListItem-padding-horizontal: 8px;
    --countryListItem-flex-basis: calc((100% - (var(--countryListContainer-gap-horizontal) * 3)) / 4);
    --countryListItem-width: var(--countryListItem-flex-basis);

    flex-basis: var(--countryListItem-flex-basis);
    width: var(--countryListItem-width);
  }
}

a.countryLink {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: auto;
  width: 100%;
  background: transparent;
  transition-property: background, border, box-shadow;
  transition-timing-function: ease-out;
  transition-duration: 250ms;
  transition-delay: 0ms;
  color: $dark;
  overflow: hidden;

  .countryFlag {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
    border-radius: 999px;
    border: 1px solid $light-gray;
    padding: 4px;
    width: 20px;
    height: 20px;
    transition-property: background, border, box-shadow;
    transition-timing-function: ease-out;
    transition-duration: 250ms;
    transition-delay: 0ms;

    img {
      display: block;
      object-fit: contain;
      object-position: center;
    }
  }

  div.countryName {
    --countryName-maxHeight: 33px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: var(--countryName-maxHeight);
    @include text-s-regular;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    transition-property: text-decoration;
    overflow: hidden;
  }

  &:hover {
    div.countryFlag {
      border-color: $dark;
    }
    div.countryName {
      text-decoration: underline;
    }
  }
}

div.countryHasEcommerce {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
}

div.countryCultureListWrapper {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: auto;
  gap: 4px;

  a.countryCultureLink {
    border-inline-start: 1px solid $dark;
    padding-inline-start: 4px;

    @include text-s-regular;
    text-decoration: none;
    transition: text-decoration 250ms ease-out 0ms;

    &:first-of-type {
      padding-inline-start: 0;
      border-inline-start: 0 none;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

div.countryListItemActive {
  @include button-shadow;

  @include sm-desktop {
    @include rounded-8;
  }

  a.countryLink div.countryFlag {
    border-color: $dark;
  }
}

// override modal styles
div.wrapper {
  --wrapper-alignItems: flex-start;
}

div.top {
  height: $topHeight;
}

div.header {
  --header-padding-vertical: 12px !important;
  --header-padding-horizontal: 18px !important;

  @include sm-desktop {
    --header-padding-vertical: 10px !important;
    --header-padding-horizontal: 16px !important;
  }
}

div.title {
  --title-gap: 14px !important;
}

div.content {
  --content-gap: 0 !important;
  --content-padding-vertical: 0 !important;
  --content-padding-horizontal: 0 !important;

  justify-content: flex-start;
  align-items: stretch;
  flex-grow: 1;
  background-color: $white;

  @include sm-desktop {
    flex-grow: 0;
  }
}
