@use "breakpoints";

$min-margin: 16px;
$md-min-margin: 32px;
$maxWidth: 1920px;

@mixin margins {
  margin-inline-start: $min-margin;
  margin-inline-end: $min-margin;
  max-width: $maxWidth;

  @include breakpoints.md-desktop {
    margin-inline-start: $md-min-margin;
    margin-inline-end: $md-min-margin;
  }

  @media (min-width: #{breakpoints.$screen-lg-desktop-min + (2 * $md-min-margin)}) {
    margin-inline-start: auto;
    margin-inline-end: auto;
    min-width: $maxWidth;
  }
}
