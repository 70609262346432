@use "styles" as *;

.servicesWraper {
  display: flex;
  flex-direction: column;
  column-gap: 12px;
  row-gap: 8px;
  width: 100%;
  @include sm-desktop {
    flex-direction: row;
  }
}

.serviceItem {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  width: 100%;
  @include sm-desktop {
    gap: 12px;
  }

  > img {
    flex-shrink: 0;
  }

  &:hover,
  &:focus {
    .iconWrapper {
      background: $dark;
      color: $white;
    }
  }
}

.serviceText {
  flex-grow: 1;
  p {
    @include text-xs-bold-uppercase;
  }
  span {
    display: block;
    @include text-xs-regular;
    margin-top: 4px;
  }
}

.iconWrapper {
  display: flex;
  height: 24px;
  width: 24px;
  background: transparent;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $dark;
  flex-shrink: 0;
}
