@use "styles" as *;

.topBar {
  position: relative;
  height: $header-topbar-mobile;
  width: 100%;
  color: $white;
  background: $dark;
  transition: height 0.3s ease-in-out;

  @include sm-desktop {
    height: $header-topbar-desktop;
  }
}

.topBarContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @include sm-desktop {
    justify-content: space-between;
  }
}

.topBarWrapper {
  display: flex;
  gap: 1rem;
}

.containerTopBarCenter {
  width: 100%;

  @include sm-desktop {
    position: absolute;
    inset-inline-start: 0;
  }
}

.topBarLink {
  @include text-xs-regular-uppercase;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  z-index: 1;

  @include sm-desktop {
    display: flex;
  }
}
