@use "styles" as *;

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 16px;
}

.separator {
  display: block;
  height: 0.5px;
  width: 100%;
  background: $light-gray;
  margin-right: 4px;
  margin-left: 4px;
}

.title {
  @include text-xs-bold-uppercase;
}

.paragraph {
  @include text-xs-regular;
  line-height: 140%;
}

.registerButton {
  text-align: center;
}

.registerWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 4px;
  padding-left: 4px;
}
