@use "styles" as *;

.collectionItem {
  display: flex;
  gap: 16px;
  &:hover,
  &:focus {
    .imageWrapper {
      @include peach-gradient;
    }
  }
}

.imageWrapper {
  padding: 2px;
  @include rounded-50-percentage;
  display: flex;
}

.preview {
  @include rounded-50-percentage;
  overflow: hidden;
  border: 2px solid $white;
  box-sizing: content-box;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.tag {
  @include text-xxs-semibold-uppercase-cta;
  @include color-gradient-light;
  padding: 4px 10px;
  width: fit-content;
  @include rounded-20;
}

.title {
  @include text-xs-bold-uppercase;
}

.titleHighlighted {
  @include text-xs-regular-uppercase;
  strong {
    @include text-xs-bold-uppercase;
  }
}

.link {
  @include text-xxs-regular;
  @include text-underline;
  line-height: 100%;
}
