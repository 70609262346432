@use "styles" as *;

.container {
  display: flex;
  gap: 12px;
}

.serviceCategoryContainer {
  @include button-shadow;
  @include rounded-8;

  background-color: $white;
  overflow: hidden;
  padding: 16px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.serviceCategoryWrapper {
  display: flex;
  gap: 12px;
  height: 100%;
}

.serviceCategoryTitle,
.serviceCategoryTitleHighlighted {
  @include text-xs-regular-uppercase-cta;
  @include color-gradient-light;
  padding: 4px 10px;
  width: fit-content;
  @include rounded-20;
  align-self: center;
  line-height: 140%;
}

.serviceCategoryTitleHighlighted {
  strong {
    @include text-xs-bold-uppercase-cta;
  }
}
