@use "styles" as *;

.container {
  padding: 0px 12px 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.topLine {
  border-top: 0.5px solid $light-gray;
  &.transparent {
    border-top: 0px solid transparent;
  }
}

.freeShippingText {
  @include text-s-regular;
}

.freeShippingMissing {
  @include text-s-bold;
}

.freeShippingMeter {
  display: flex;
  align-items: center;
  gap: 8px;
}

.freeShippingTotal {
  @include text-xs-regular-uppercase;
}

.freeShippingBar {
  @include rounded-8;

  position: relative;
  height: 8px;
  flex: 1;
  border: 0.5px solid red;
  background-color: white;
  overflow: hidden;
}

.freeShippingFill {
  @include rounded-8;
  @include kisses-gradient;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 8px;
  max-width: 100%;
}

.totalContainer {
  @include text-s-bold;

  padding-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.center {
  text-align: center;
}
