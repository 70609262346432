@use "styles" as *;

.serviceItemContainer {
  --double-column: calc(2 * var(--column) + 12px);
  --tenth-double: calc(var(--double-column) / 10);
  @include sm-desktop {
    --column: calc((100vw - 24px - 132px) / 12);
  }
  @include md-desktop {
    --column: calc((100vw - 64px - 132px) / 12);
  }
  @include lg-desktop {
    --column: calc((1920px - 132px) / 12);
  }

  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  width: calc(var(--double-column) - var(--tenth-double));
}

.mediaContainer {
  position: relative;
  aspect-ratio: 1;
  min-height: 80px;
  overflow: hidden;
  @include rounded-8;
}

.serviceItemTitle {
  @include text-xs-bold-uppercase;
  margin: auto 0;
}

.serviceItemTitleHighlighted {
  @include text-xs-regular-uppercase;
  strong {
    @include text-xs-bold-uppercase;
  }
  margin: auto 0;
}

.link {
  margin: 12px 0 8px;
}

.serviceItemContainerMobile {
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: space-between;
}

.serviceItemContainerDescriptionMobile {
  width: 50%;
}

.serviceItemTitleMobile {
  @include text-xs-bold-uppercase;
}

.serviceItemTitleMobileHighlighted {
  @include text-xs-regular-uppercase;
  strong {
    @include text-xs-bold-uppercase;
  }
}

.paragraph {
  @include text-xxs-regular;
  line-height: 140%;
}

.iconArrow {
  display: inline;
  vertical-align: middle;
}
