@use "styles" as *;

.footer {
  padding-top: 80px;
  @include glass-shadow;
  @include sm-desktop {
    padding-top: 40px;
  }
}

.accordion1,
.accordion2,
.accordion3 {
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid $dark;
  grid-column: 1 / span 12;

  @include sm-desktop {
    gap: 8px;
  }

  .trigger {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .accordionButton {
    display: flex;
    align-items: center;
    padding: 12px 0;
    justify-content: space-between;
    @include text-xxs-bold-uppercase;

    @include sm-desktop {
      padding: 0;
    }
  }

  .panel {
    display: grid;
    @include accordion-close;

    @include sm-desktop {
      display: block;
      visibility: visible;
    }
  }

  .panel[data-headlessui-state="open"] {
    @include accordion-open;
  }

  .panelInner {
    @include text-xs-regular;
    line-height: 1rem;
    overflow: hidden;

    > * {
      padding: 8px 0 12px;
      @include sm-desktop {
        padding: 0;
      }
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .richTextContainer {
    display: flex;
    gap: 12px;
    flex-direction: column;
  }

  @include sm-desktop {
    border-bottom: 0;
  }
}

.accordion1 {
  @include sm-desktop {
    gap: 12px;
    grid-column: 6 / span 3;
    grid-row: 2;
    align-self: flex-end;
  }
}

.accordion2 {
  @include sm-desktop {
    grid-column: 9 / span 2;
    grid-row: 1 / span 2;
  }
}

.accordion3 {
  @include sm-desktop {
    grid-column: 11 / span 2;
    grid-row: 1 / span 2;
  }
}

.loyaltyProgramContainer {
  margin-top: 40px;
  padding: 12px 0;
  display: flex;
  gap: 12px;
  align-items: center;
  grid-column: 1 / span 12;
  grid-row: span 2;

  @include sm-desktop {
    margin-top: 0;
    padding: 0;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 2fr;
    align-items: flex-start;
  }
  @include lg-desktop {
    align-items: center;
  }

  .loyaltyProgramDescription {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include sm-desktop {
      align-self: center;
    }
    .loyaltyProgramTitle {
      @include text-xs-bold-uppercase;
    }
    .loyaltyProgramTitleHighlighted {
      @include text-xs-regular-uppercase;
      strong {
        @include text-xs-bold-uppercase;
      }
    }
  }

  .loyaltyProgramParagraph {
    @include text-xs-regular;
    line-height: 140%;

    @include sm-desktop {
      grid-column: span 2;
    }

    @include md-desktop {
      width: 80%;
    }
    @include lg-desktop {
      width: 50%;
    }
  }

  .loyaltyProgramImage {
    position: relative;
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    overflow: hidden;
    @include rounded-8;

    @include sm-desktop {
      width: 84px;
      height: 84px;
      order: -1;
    }

    @include md-desktop {
      width: 108px;
      height: 108px;
    }

    @include lg-desktop {
      width: 155px;
      height: 155px;
    }
    .image {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .loyaltyProgramLink {
    @include text-xxs-regular;
    @include text-underline;

    @include sm-desktop {
      grid-column: span 2;
    }
  }

  @include sm-desktop {
    margin-bottom: 24px;
    grid-column: 6 / span 3;
    grid-row: 1;
  }
}

.bannerContainer {
  padding: 32px 24px 40px;
  margin-top: 32px;
  overflow: hidden;
  grid-column: span 12;
  text-align: center;
  @include text-xl-regular;
  @include md-desktop {
    padding: 32px 0 40px;
    @include text-xxl-regular;
  }

  &.white {
    background: $white;
  }
  &.pink {
    background: $light-pink;
  }
  &.gradient {
    @include color-gradient-light;
  }

  &.bannerTitleHighlighted {
    strong {
      @include text-xl-semibold;
      @include md-desktop {
        @include text-xxl-semibold;
      }
    }
  }
}

.socialLinksContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 24px;
  row-gap: 8px;
  align-items: center;
  margin: 24px 0;
  @include text-xs-bold-uppercase;
  grid-column: 1 / span 12;

  @include sm-desktop {
    flex-wrap: nowrap;
    justify-content: unset;
    margin: 24px 0 0;
    grid-column: 1 / span 4;
    grid-row: 3;
    @include text-xxs-bold-uppercase;
  }

  .socialLinks {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  &.LoyaltyProgramIsRemove {
    @include sm-desktop {
      grid-column: 1 / span 4;
    }
  }
}

.downloadContainer {
  grid-column: 1 / span 12;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;

  @include sm-desktop {
    grid-column: 6 / span 3;
    grid-row: 3;
    justify-content: unset;
    margin-top: 24px;
  }

  .goToStore {
    position: relative;
  }

  .mediaGoogle:focus-within,
  .mediaApple:focus-within {
    outline: 1px solid;
    @include rounded-4;
    outline-color: $gray;
    outline-color: -webkit-focus-ring-color;
  }

  &.LoyaltyProgramIsRemove {
    @include sm-desktop {
      grid-column: 6 / span 3;
    }
  }
}

.countryContainer {
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / span 12;
  justify-content: space-between;
  margin: 32px -16px 0;
  padding: 12px 16px;
  @include rounded-4;
  @include button-shadow;
  @include glass-gradient;

  @include sm-desktop {
    grid-column: 9 / span 4;
    grid-row: 3;
    margin: 24px 0 0 0;
  }

  .countryName,
  .countryLanguage {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .fontBase {
    @include text-xxs-regular-uppercase;
  }

  .fontHighlighted {
    @include text-xxs-bold-uppercase;
  }

  .countryChange {
    display: flex;
    gap: 4px;
    align-items: center;

    .fontBase {
      @include text-underline;
    }
  }
}

.legalContainer {
  grid-column: 1 / span 12;
  margin-top: 18px;
  padding: 8px 32px 60px;
  background-color: $dark;

  margin-top: 4px;

  @include sm-desktop {
    margin-top: 40px;
    padding: 8px 32px;
  }

  @include md-desktop {
    padding: 8px 32px;
  }
}

.legalText {
  @include text-3xs-regular;
  text-align: center;
  color: $white;
}

.mobileOnly {
  @include sm-desktop {
    display: none;
  }
}

.desktopOnly {
  @include mobile-only {
    display: none;
  }
}
