@use "styles" as *;

.container {
  @include rounded-8;
  @include container-shadow;

  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.divider {
  height: 0.5px;
  background-color: $light-gray;
  flex-shrink: 0;
}

.savedForLaterTitle {
  @include text-l-regular;
  padding: 0 8px;
  font-weight: 400;
}

.undoItem {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 16px;

  .content {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 1 auto;

    p {
      @include text-s-regular;
      color: $success;
    }

    .undoButton {
      text-decoration: underline;
      flex: 0 0 20px;
    }
  }
}

.wrapper {
  --wrapper-alignItems: flex-start;
  .content {
    padding: 20px 16px;
  }

  @include sm-desktop {
    --wrapper-alignItems: center;
    max-width: 464px;
  }
}
