@use "styles" as *;

.hiddenNav {
  visibility: hidden;
  position: absolute;
  display: flex;
  list-style-position: inside;
  overflow: auto;
  background-color: $light-pink;
  max-width: 100svh;
  ul {
    margin-left: 1rem;
  }
}
