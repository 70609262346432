@use "styles" as *;

.linksContainer {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  gap: 12px;
  @include hide-scrollbar;
  @media (max-width: 1279px) {
    margin-inline: -16px;
  }
}

.linkBox {
  @include text-s-regular-uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  &:first-child {
    margin-inline-start: 16px;
  }
  &:last-child {
    margin-inline-end: 16px;
  }
  @include sm-desktop {
    &:first-child {
      margin-inline-start: auto;
    }
    &:last-child {
      margin-inline-end: auto;
    }
  }
}

.imgWrapper {
  position: relative;
  aspect-ratio: 1;
  width: 144px;
  img {
    object-fit: cover;
  }
  @include sm-desktop {
    width: 200px;
  }
  @media (any-hover: hover) {
    .imgBackground {
      // little bouncy effect
      transition: transform 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      transform: scale(0.85);
    }
    &:hover {
      .imgBackground {
        transform: scale(1);
      }
    }
  }
}
.imgForeground {
  z-index: 1;
}
.imgBackground {
  position: absolute;
  top: 0;
  left: 0;
}
