@use "styles" as *;

.form {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 12px;
  @include sm-desktop {
    gap: 8px;
  }
}

.label {
  flex-grow: 0 !important;
  flex-shrink: 0;
}

.input {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
    appearance: none;
  }
  @include text-s-regular;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: initial;
  flex-grow: 1;
  outline: none;
  background: initial;
  &:focus-visible {
    outline: 1px $dark;
    outline-offset: 2px;
  }
  &::placeholder {
    color: $dark;
  }
}
